// console.log("===========>>>> infoSitio", process.env.GATSBY_NETLIFY_SITE_ID)

const infoSitio = {
  // descripcion:
  //   "Tienda de EJEMPLO de productos truper a Mayoreo, con envíos a todo México.",
  // negocio: "Mi Tienda",
  // direccion: ["Oaxaca", "México"],
  // mail: "ventas@cabbur.me",
  // telefono: "971 142 4420",
  //
  // netlifySiteId: "7a5efe4d-51d6-4d15-80bc-da5281ddb510",
  // netlifySiteId: "7a5efe4d-51d6-4d15-80bc-da5281ddb510_DEV",
  // netlifySiteId: "e0017abe-36b7-4447-b44a-14827493a3b6",
  //TODO IMPORTANTE dejar todo en terminos de GATSBY_NETLIFY_SITE_ID
  netlifySiteId: process.env.GATSBY_NETLIFY_SITE_ID,
  // infoSitio:process.env.NETLIFY_SITE_ID,
  linksFooter: {
    links: [
      // { link: "/noticias", text: "NOTICIAS" },
      // { link: "/contacto", text: "CONTACTO" },
      // { link: "/recursos", text: "RECURSOS" },
      {
        // link: "/noticia/los-murales-el-directorio-de-negocios-y-emprendedores-mas-importante-del",
        link: "/aviso-privacidad",
        text: "AVISO DE PRIVACIDAD",
      },
    ],
  },
}

export default infoSitio
